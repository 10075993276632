import React from 'react'

export default function NonPartisanCopy() {
  return (
    <div id="non-partisan-copy">
      <h1 className="blue">Vote safe.</h1>
      <h1>Make sure your vote is counted by Election Night.</h1>
      <h3>Get the best voting options for your state. Learn when it’s better to:</h3>
      <p>+ vote by mail</p>
      <p>+ vote in person early</p>
      <p>+ vote in person on Nov 3</p>
    </div>
  )
}
